<template>
  <div class="page-container">
    <div class="header-body">
      <el-card>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="add-btn">
              <el-button type="primary" @click="addData.isOpen = !addData.isOpen">添加</el-button>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div class="table-body">
      <el-card>
        <el-table :data="roleList" style="width: 100%" border stripe>
          <el-table-column type="index" label="ID" width="50">
          </el-table-column>
          <el-table-column prop="roleName" label="角色"> </el-table-column>
          <el-table-column prop="gmtCreate" label="创建时间"></el-table-column>
          <el-table-column label="操作" width="300">
            <template v-slot="scoped">
              <el-button type="primary" size="small" @click="openSetRoleDialog(scoped.row.id)">分配菜单</el-button>
              <el-button type="warning" size="small" @click="updateUserHandle(scoped.row.id)">编辑</el-button>
              <el-button type="danger" size="small" @click="deleteRole(scoped.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <div class="pagination-body">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <set-role-menu :dialogData="dialogData" @closeDialog="closeDialog"></set-role-menu>
    <add-role :addData="addData"  @closeDialog="closeDialog"></add-role>
    <edit-role :editData="editData" @closeDialog="closeDialog"></edit-role>
  </div>
</template>

<script>
import { getRoleList, deleteRole } from '@/api/login.js'
import setRoleMenu from './dialog/setRoleMenu.vue'
import addRole from './dialog/addRole.vue'
import editRole from './dialog/editRole.vue'

export default {
  components: {
    setRoleMenu,
    addRole,
    editRole
  },
  data () {
    return {
      roleList: [],
      queryInfo: {
        currentPage: 1,
        pageSize: 10
      },
      total: 0,
      isAddDialog: false,
      dialogData: {
        isOpen: false,
        id: ''
      },
      addData: {
        isOpen: false
      },
      editData: {
        isOpen: false,
        id: ''
      }
    }
  },
  created () {
    this.getRoleList()
  },
  methods: {
    /* 获取用户列表 */
    getRoleList () {
      getRoleList(this.queryInfo).then((res) => {
        this.roleList = res.data.data
        this.total = res.data.total
      })
    },
    /* 分页选择 */
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getRoleList()
    },
    handleCurrentChange (size) {
      this.queryInfo.currentPage = size
      this.getRoleList()
    },
    /* 打开更新窗口 */
    updateUserHandle (id) {
      this.editData = {
        isOpen: true,
        id: id
      }
    },
    /* 打开分配角色弹窗 */
    openSetRoleDialog (id) {
      this.dialogData = {
        isOpen: true,
        id: id
      }
    },
    /* 关闭弹窗 */
    closeDialog (data) {
      this.dialogData.isOpen = data.isOpen
      this.addData.isOpen = data.isOpen
      this.editData.isOpen = data.isOpen
      if (data.type === 'success') {
        this.getRoleList()
      }
    },
    /* 删除用户 */
    deleteRole (id) {
      this.$confirm('此操作将永久删除该专业, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteRole(id).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getRoleList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  .header-body {
    .add-btn {
      text-align: right;
    }
  }
  .pagination-body {
      .el-pagination {
        text-align: center;
        padding: 20px 0;
      }
    }
}
</style>
